import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/context/AuthContext';
import { useRouter } from 'next/navigation';

export const useMembership = () => {
  const [membership, setMembership] = useState(null);
  const [credits, setCredits] = useState(0);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const router = useRouter();

  const getUserId = () => user?.id;

  const fetchMembershipData = async () => {
    try {
      if (!user) return null;

      // Only check membership for workers
      if (user.user_metadata?.role !== 'worker') {
        return null;
      }

      // Mai întâi verificăm dacă există vreun abonament activ
      const { data: memberships, error: checkError } = await supabase
        .from('worker_memberships')
        .select(`
          id,
          status,
          created_at,
          membership:memberships (
            id,
            name,
            price,
            features
          )
        `)
        .eq('worker_id', user.id)
        .eq('status', 'active')
        .order('created_at', { ascending: false });

      if (checkError) throw checkError;

      // Dacă există un abonament activ, îl returnăm
      if (memberships && memberships.length > 0) {
        return memberships[0];
      }

      // Obținem ID-ul abonamentului Basic
      const { data: basicMembership, error: membershipError } = await supabase
        .from('memberships')
        .select('id')
        .eq('name', 'Basic')
        .single();

      if (membershipError) throw membershipError;

      // Verificăm dacă există deja un membership pentru acest utilizator
      const { data: existingMembership, error: existingError } = await supabase
        .from('worker_memberships')
        .select('id')
        .eq('worker_id', user.id)
        .single();

      if (existingMembership) {
        // Dacă există, îl actualizăm la Basic și activ
        const { data: updatedMembership, error: updateError } = await supabase
          .from('worker_memberships')
          .update({
            membership_id: basicMembership.id,
            status: 'active'
          })
          .eq('id', existingMembership.id)
          .select()
          .single();

        if (updateError) throw updateError;
        return updatedMembership;
      } else {
        // Dacă nu există, creăm unul nou
        const { data: newMembership, error: insertError } = await supabase
          .from('worker_memberships')
          .insert({
            worker_id: user.id,
            membership_id: basicMembership.id,
            status: 'active'
          })
          .select()
          .single();

        if (insertError) throw insertError;
        return newMembership;
      }
    } catch (error) {
      console.error('Error fetching membership data:', error.message || error);
      console.error('Full error:', error);
      return null;
    }
  };

  const checkAndAddMonthlyCredits = async (monthlyCredits) => {
    try {
      const userId = getUserId();
      if (!userId) return;

      const { data: creditsData, error: creditsError } = await supabase
        .from('credits')
        .select('last_monthly_credit_at')
        .eq('worker_id', userId)
        .single();

      if (creditsError && creditsError.code !== 'PGRST116') throw creditsError;

      const lastCreditDate = creditsData?.last_monthly_credit_at ? new Date(creditsData.last_monthly_credit_at) : null;
      const now = new Date();
      const shouldAddCredits = !lastCreditDate || 
        (now.getMonth() !== lastCreditDate.getMonth() || now.getFullYear() !== lastCreditDate.getFullYear());

      if (shouldAddCredits) {
        const { error } = await supabase.rpc('add_monthly_credits', {
          p_worker_id: userId,
          p_amount: monthlyCredits
        });

        if (error) throw error;

        // Update local state
        setCredits(prev => prev + monthlyCredits);
      }
    } catch (error) {
      console.error('Error adding monthly credits:', error);
    }
  };

  const hasPhoneAccess = async (jobId) => {
    try {
      if (!user || !jobId) return false;

      const { data, error } = await supabase
        .from('phone_access')
        .select('id')
        .eq('worker_id', user.id)
        .eq('job_id', jobId)
        .maybeSingle();

      if (error) {
        // Dacă tabela nu există sau altă eroare, logăm și returnăm false
        console.error('Error checking phone access:', error.message || error);
        return false;
      }

      return !!data;
    } catch (error) {
      console.error('Error in hasPhoneAccess:', error.message || error);
      return false;
    }
  };

  const fetchCredits = async () => {
    try {
      const userId = getUserId();
      if (!userId) return;

      // Verificăm dacă există tranzacții
      let { data: transactions, error: transactionsError } = await supabase
        .from('credit_transactions')
        .select('amount, type')
        .eq('worker_id', userId);

      if (transactionsError) throw transactionsError;

      // Dacă nu există tranzacții și userul e worker, creăm creditele inițiale
      if ((!transactions || transactions.length === 0) && user?.user_metadata?.role === 'worker') {
        console.log('No transactions found, creating initial credits...');
        
        const { error: creditError } = await supabase
          .from('credit_transactions')
          .insert({
            worker_id: userId,
            amount: 3,
            type: 'welcome_bonus',
            description: 'Credite de bun venit',
            created_at: new Date().toISOString()
          });

        if (creditError) throw creditError;

        // Reîncercăm să luăm tranzacțiile după creare
        const { data: newTransactions, error: newTransactionsError } = await supabase
          .from('credit_transactions')
          .select('amount, type')
          .eq('worker_id', userId);

        if (newTransactionsError) throw newTransactionsError;
        
        // Actualizăm transactions cu noile date
        transactions = newTransactions;
      }

      // Calculăm balanța din toate tranzacțiile
      const availableCredits = transactions?.reduce((total, transaction) => {
        return total + transaction.amount;
      }, 0) || 0;

      console.log('Available credits:', availableCredits);
      setCredits(availableCredits);
      
    } catch (error) {
      console.error('Error in fetchCredits:', error);
    }
  };

  const useCredit = async (jobId) => {
    try {
      const userId = getUserId();
      if (!userId) {
        return { success: false, message: 'Utilizatorul nu este autentificat.' };
      }

      if (!jobId) {
        return { success: false, message: 'ID-ul jobului este invalid.' };
      }

      // Verificăm dacă utilizatorul are deja acces
      const hasAccess = await hasPhoneAccess(jobId);
      if (hasAccess) {
        return { success: true };
      }

      // Calculăm creditele disponibile din credit_transactions
      const { data: transactions, error: transactionError } = await supabase
        .from('credit_transactions')
        .select('amount, type')
        .eq('worker_id', userId);

      if (transactionError) throw transactionError;

      // Calculăm balanța
      const balance = transactions.reduce((total, transaction) => {
        return total + transaction.amount;
      }, 0);

      if (balance < 1) {
        return { success: false, message: 'Nu ai suficiente credite.' };
      }

      // Adăugăm tranzacția de folosire credit
      const { error: useError } = await supabase
        .from('credit_transactions')
        .insert({
          worker_id: userId,
          job_id: jobId,
          amount: -1,
          type: 'used',
          description: 'Credit folosit pentru vizualizare număr de telefon'
        });

      if (useError) throw useError;

      // Adăugăm accesul la telefon
      const { error: accessError } = await supabase
        .from('phone_access')
        .insert({
          worker_id: userId,
          job_id: jobId
        });

      if (accessError) throw accessError;

      // Update local state
      setCredits(balance - 1);
      
      return { success: true };
    } catch (error) {
      console.error('Error in useCredit:', error);
      return { success: false, message: 'A apărut o eroare. Te rugăm să încerci din nou.' };
    }
  };

  const handleUpgrade = async (planName) => {
    if (!user) {
      router.push('/register/worker');
      return;
    }

    setLoading(true);
    try {
      if (planName === 'Pro') {
        // TODO: Aici vom adăuga integrarea cu Stripe
        // 1. Vom crea o sesiune de checkout
        // 2. Vom redirecționa userul către pagina de plată Stripe
        // 3. După plată reușită, vom face upgrade-ul

        // Temporar, doar afișăm un mesaj
        alert('În curând vei putea face upgrade la Pro prin plată cu cardul!');
        setLoading(false);
        return;
      }

      // Pentru Basic (gratuit) păstrăm logica existentă
      // 1. Dezactivăm orice abonamente active existente
      const { error: deactivateError } = await supabase
        .from('worker_memberships')
        .update({ status: 'inactive' })
        .eq('worker_id', user.id)
        .eq('status', 'active');

      if (deactivateError) throw deactivateError;

      // 2. Obținem ID-ul pentru Basic
      const { data: membershipData, error: membershipError } = await supabase
        .from('memberships')
        .select('id')
        .eq('name', 'Basic')
        .single();

      if (membershipError) throw membershipError;

      // 3. Creăm noul membership Basic
      const { data: newMembership, error: membershipCreateError } = await supabase
        .from('worker_memberships')
        .insert({
          worker_id: user.id,
          membership_id: membershipData.id,
          status: 'active'
        })
        .select()
        .single();

      if (membershipCreateError) throw membershipCreateError;

      // 4. Verificăm dacă există credite în credit_transactions
      const { data: existingTransactions, error: transactionCheckError } = await supabase
        .from('credit_transactions')
        .select('id')
        .eq('worker_id', user.id)
        .limit(1);

      if (transactionCheckError) throw transactionCheckError;

      // Dacă nu există tranzacții, creăm creditele inițiale
      if (!existingTransactions?.length) {
        // Adăugăm tranzacția inițială cu 3 credite
        const { error: creditError } = await supabase
          .from('credit_transactions')
          .insert({
            worker_id: user.id,
            amount: 3,
            type: 'welcome_bonus',
            description: 'Credite de bun venit',
            created_at: new Date().toISOString()
          });

        if (creditError) throw creditError;
      }

      // 5. Actualizăm starea locală
      const updatedMembershipData = await fetchMembershipData();
      setMembership(updatedMembershipData);
      await fetchCredits(); // Reîmprospătăm creditele

      return { success: true };
    } catch (error) {
      console.error('Error handling upgrade:', error);
      return { success: false, message: error.message };
    } finally {
      setLoading(false);
    }
  };

  const canViewPhone = async (jobId) => {
    if (!jobId) return false;
    
    // Dacă utilizatorul are membership Pro, poate vedea toate numerele
    if (membership?.membership?.name === 'Pro') {
      return true;
    }

    // Verificăm dacă utilizatorul a plătit deja pentru acest număr
    const hasAccess = await hasPhoneAccess(jobId);
    if (hasAccess) {
      return true;
    }

    // Dacă utilizatorul are credite disponibile
    return credits > 0;
  };

  useEffect(() => {
    if (user) {
      // Initial data fetch
      const initializeData = async () => {
        try {
          const membershipData = await fetchMembershipData();
          setMembership(membershipData);
          await fetchCredits(); // Initial credits fetch
        } catch (error) {
          console.error('Error initializing membership data:', error);
        }
      };

      initializeData();

      // Set up subscriptions for both credits and credit_transactions
      const creditsChannel = supabase
        .channel('credits_changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'credits',
            filter: `worker_id=eq.${user.id}`
          },
          async () => {
            console.log('Credits table changed, refreshing...');
            await fetchCredits();
          }
        )
        .subscribe();

      const transactionsChannel = supabase
        .channel('transactions_changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'credit_transactions',
            filter: `worker_id=eq.${user.id}`
          },
          async () => {
            console.log('Credit transactions changed, refreshing...');
            await fetchCredits();
          }
        )
        .subscribe();

      // Refresh credits periodically (every minute)
      const interval = setInterval(fetchCredits, 60000);

      return () => {
        supabase.removeChannel(creditsChannel);
        supabase.removeChannel(transactionsChannel);
        clearInterval(interval);
      };
    }
  }, [user]);

  return {
    membership: membership?.membership,
    credits,
    loading,
    handleUpgrade,
    useCredit,
    refreshMembership: fetchMembershipData,
    canViewPhone,
    hasPhoneAccess
  };
}; 